























































































import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import { dispatch, ListQuery, Comment, User, TasksChain } from "@/store";

export default Vue.extend({
  data() {
    return {
      query: {
        page_number: 1,
        page_size: 10,
        ordering: "-id",
        content_type: "coin_task",
        user_id: -1
      } as ListQuery,
      list: [] as Comment[],
      viewedUser: {} as User,
      count: 0
    };
  },
  computed: {
    ...mapState({
      currentUser: "user",
      notifications: "notifications"
    }),
    ...mapState("app", {
      language: "language",
      isMobile: "isMobile"
    }),
    ...mapState("assets", {
      images: "images"
    }),
    ...mapGetters(["allow"]),
    ...mapState("tasks", {
      chainList: "chainList"
    }),
    viewedId(): number {
      const { id } = this.$route.params;
      return id ? Number(id) : 0;
    },
    user(): User {
      return this.viewedId ? this.viewedUser : this.currentUser;
    },
    unread(): number {
      return this.notifications.unreadCount > 99
        ? 99
        : this.notifications.unreadCount;
    }
  },
  methods: {
    tasksMentionedList(query: ListQuery) {
      return dispatch.tasksGetList(query);
    },
    tasksGetCommentList(query: ListQuery) {
      return dispatch.userTaskRecord(query);
    },
    onUserEdit() {
      if (this.viewedId) {
        return;
      }
      this.$router.push(this.$paths.userEdit);
    },
    chainName(id: number): string {
      const chain = this.chainList.find((i: TasksChain) => i.id === id);
      if (chain && chain.id !== 1) {
        return "[" + chain.coin_name + "]";
      }
      return "";
    },
    onCoinRuleClick() {
      window.open("https://www.angrymiao.com/dao/479/", "_black");
    }
  },
  mounted() {
    if (!this.currentUser.isSignIn) {
      dispatch.userSignIn();
    } else if (this.viewedId) {
      if (this.viewedId === this.currentUser.id) {
        window.location.href = this.$paths.userCenter;
        return;
      }
      dispatch
        .userGetList({ ids: [this.viewedId], page_number: 1, page_size: 1 })
        .then(res => {
          if (res.results[0]) {
            this.viewedUser = res.results[0];
            this.query.user_id = this.viewedUser.id;
          }
        });
    } else {
      dispatch.userBalance();
    }
  }
});
