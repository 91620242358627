var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-center",class:{ mobile: _vm.isMobile }},[_c('v-header'),_c('div',{staticClass:"container px"},[(_vm.user.id)?_c('div',{staticClass:"user"},[_c('v-avatar',{staticClass:"avatar",class:{ viewed: _vm.viewedId },attrs:{"src":_vm.user.avatar},on:{"click":_vm.onUserEdit}},[(!_vm.viewedId)?_c('i',{staticClass:"iconfont icon-bianji1",on:{"click":_vm.onUserEdit}}):_vm._e()]),_c('v-user-group',{staticClass:"group",attrs:{"group":_vm.user.group}}),_c('div',{staticClass:"nickname"},[_vm._v(" "+_vm._s(_vm.user.nickname)+" ")]),(!_vm.viewedId)?_c('div',{staticClass:"id"},[_vm._v("Angry Miao ID: "+_vm._s(_vm.user.id))]):_vm._e(),(!_vm.viewedId)?[_c('div',{staticClass:"coin"},[_c('v-coin-send-modal',[_c('i',{staticClass:"iconfont icon-send"})]),_c('i',{staticClass:"iconfont icon-wenhao",on:{"click":_vm.onCoinRuleClick}})],1),_vm._l((_vm.user.balance),function(item){return _c('div',{key:item.id,staticClass:"coin"},[_c('img',{staticClass:"icon",attrs:{"src":_vm.images.miao_coin,"alt":""}}),_vm._v(" "+_vm._s(Math.floor(item.sum))+" MIAO COIN "+_vm._s(_vm.chainName(item.coin_id))+" ")])})]:_vm._e()],2):_vm._e(),(_vm.user.id && !_vm.viewedId)?_c('md-tabs',{staticClass:"tabs",attrs:{"md-active-tab":_vm.$route.query.active_tab},scopedSlots:_vm._u([{key:"md-tab",fn:function(ref){
var tab = ref.tab;
return [_vm._v(" "+_vm._s(tab.label)+" "),(tab.data.badge)?_c('i',{staticClass:"badge"},[_vm._v(_vm._s(tab.data.badge))]):_vm._e()]}}],null,false,481792298)},[_c('md-tab',{staticClass:"tab",attrs:{"id":"tasks","md-label":_vm.$t('task.myTask')}},[_c('v-task-list',{attrs:{"query":{
            language: _vm.language,
            is_current_user: true
          },"tasksGetList":_vm.tasksMentionedList}})],1),_c('md-tab',{staticClass:"tab",attrs:{"id":"mentioned","md-label":_vm.$t('task.myMentioned')}},[_c('v-task-list',{attrs:{"query":{
            language: _vm.language,
            mention_user_id: _vm.user.id
          },"tasksGetList":_vm.tasksMentionedList}})],1),_c('md-tab',{staticClass:"tab",attrs:{"id":"comment","md-label":_vm.$t('task.myComment')}},[_c('v-task-list',{attrs:{"query":{
            language: _vm.language,
            user_id: _vm.user.id
          },"tasksGetList":_vm.tasksGetCommentList}})],1),_c('md-tab',{staticClass:"tab",attrs:{"id":"message","md-template-data":{ badge: _vm.unread },"md-label":_vm.$t('task.myMessage')}},[_c('v-notification-list')],1),_c('md-tab',{staticClass:"tab",attrs:{"id":"miaocoin","md-label":"MIAO COIN"}},[_c('v-balance-list',{attrs:{"query":{}}})],1)],1):_vm._e()],1),_c('v-footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }